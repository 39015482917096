import logo from './images/logo.jpg';
import 'semantic-ui-css/semantic.min.css'
import FormAsamblea from './forms/form'

import { Container, Image, Header, Segment} from 'semantic-ui-react'

function App() {
  return (
    <div>
      <Image src={logo} size='large' centered/>
      <Container>
        <Header as='h2'>Firmá!</Header>
        <Segment inverted>
          <p>Firmá urgente el aval para la presentación de la Lista Verde.</p>
          <p>A todas las músicas y los músicos afiliados al SADEM les pedimos que avalen nuestra petición para que
          no expulsen del sindicato a todos los afiliados y afiliadas por falta de pago durante el 2020.</p>
          <p>Evitemos el fraude electoral, permitiendo la presentación de listas y la votación de todo afiliado/a.</p>
          <Segment raised color="grey" textAlign='center'>
              <p>Lista verde</p>
              <p>Asamblea de trabajadores de la música</p>
          </Segment>
        </Segment>
        <br/>
        <FormAsamblea/>
      </Container>
    </div>
  );
}

export default App;
