import React from 'react';
import { Formik, } from 'formik';
import { Form, Input, Select, TextArea, SubmitButton } from 'formik-semantic-ui-react';
import { Segment, Container, FormGroup} from 'semantic-ui-react'
import * as yup from 'yup';
import axios from 'axios';

const SERVERLESS_FN_URL = " https://asamblea-wrkr.alexander-elgier.workers.dev/submit";

const OBLIGATORIO = 'Este campo es obligatorio.';

let schema = yup.object().shape({
  n_afiliado: yup.number().required(OBLIGATORIO).positive("Debe ser un número positivo.").integer("Debe ser un número entero."),
  apellido: yup.string().required(OBLIGATORIO),
  nombre: yup.string().required(OBLIGATORIO),
  dni: yup.number().required(OBLIGATORIO).positive("Debe ser un número positivo.").integer("Debe ser un número entero."),
  ciudad: yup.string().required(OBLIGATORIO),
  provincia: yup.string().required(OBLIGATORIO),
  email: yup.string().email("Por favor, escribe una dirección de correo válida"),
  telefono: yup.number().positive("Debe ser un número positivo.").integer("Debe ser un número entero."),
  comentario: yup.string(),
});

const provincias = [
 { key: "Buenos Aires", value: "Buenos Aires", text: "Buenos Aires" },
 { key: "Capital Federal", value: "Capital Federal", text: "Capital Federal" },
 { key: "Catamarca", value: "Catamarca", text: "Catamarca" },
 { key: "Chaco", value: "Chaco", text: "Chaco" },
 { key: "Chubut", value: "Chubut", text: "Chubut" },
 { key: "Córdoba", value: "Córdoba", text: "Córdoba" },
 { key: "Corrientes", value: "Corrientes", text: "Corrientes" },
 { key: "Entre Ríos", value: "Entre Ríos", text: "Entre Ríos" },
 { key: "Formosa", value: "Formosa", text: "Formosa" },
 { key: "Jujuy", value: "Jujuy", text: "Jujuy" },
 { key: "La Pampa", value: "La Pampa", text: "La Pampa" },
 { key: "La Rioja", value: "La Rioja", text: "La Rioja" },
 { key: "Mendoza", value: "Mendoza", text: "Mendoza" },
 { key: "Misiones", value: "Misiones", text: "Misiones" },
 { key: "Neuquén", value: "Neuquén", text: "Neuquén" },
 { key: "Río Negro", value: "Río Negro", text: "Río Negro" },
 { key: "Salta", value: "Salta", text: "Salta" },
 { key: "San Juan", value: "San Juan", text: "San Juan" },
 { key: "San Luis", value: "San Luis", text: "San Luis" },
 { key: "Santa Cruz", value: "Santa Cruz", text: "Santa Cruz" },
 { key: "Santa Fe", value: "Santa Fe", text: "Santa Fe" },
 { key: "Santiago del Estero", value: "Santiago del Estero", text: "Santiago del Estero" },
 { key: "Tierra del Fuego", value: "Tierra del Fuego", text: "Tierra del Fuego" },
 { key: "Tucumán", value: "Tucumán", text: "Tucumán" }
];


const FormAsamblea = () => {
   return (
    <Container>
        <Formik
            initialValues={{
                nombre: '',
                apellido: '',
                email: '',
                dni: '',
                n_afiliado: '',
                ciudad: '',
                provincia: '',
                telefono: '',
                comentario: '',
            }}
            validationSchema={schema}
            onSubmit={(values, actions) => {
                axios({
                  method: "POST",
                  url: SERVERLESS_FN_URL,
                  data: values
                })
                  .then(response => {
                    console.log(response)
                    actions.setSubmitting(false);
                    actions.resetForm();
                    alert("Gracias!");
                  })
                  .catch(error => {
                    actions.setSubmitting(false);
                    alert("Hubo un error");
                });
            }}
        >
            <Segment>
            <Form>
                <FormGroup widths='equal'>
                    <Input name="n_afiliado" label="Número de Afiliado" errorPrompt/>
                    <Input name="apellido" label="Apellido" errorPrompt/>
                    <Input name="nombre" label="Nombre" errorPrompt/>
                    <Input name="dni" label="DNI" errorPrompt/>
                </FormGroup>
                <FormGroup widths='equal'>
                    <Input name="ciudad" label="Ciudad" errorPrompt/>
                    <Select name="provincia" label="Provincia" errorPrompt options={provincias}/>
                    <Input type="email" name="email" label="Email" errorPrompt/>
                    <Input name="telefono" label="Teléfono" errorPrompt/>
                </FormGroup>
                <TextArea name="comentario" label="Comentarios" errorPrompt/>
                <SubmitButton fluid primary>Firmar</SubmitButton>
            </Form>
            </Segment>
        </Formik>
        <br/>
    </Container>
);
}

export default FormAsamblea;
